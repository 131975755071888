import { FadeIn, ShowUp } from "components/Motions";

function About({ fadeInDuration, showUpDuration }) {
  return (
    <FadeIn duration={fadeInDuration}>
      <div className="overflow-x-hidden mt-12">
        <div className='flex flex-col lg:flex-row justify-between text-xs lg:my-10 px-4 py-8 lg:py-12 md:px-20 lg:px-20 xl:px-32 2xl:px-56'>
          <div className='px-4 lg:px-20 xl:px-40 py-8 lg:basis-6/12 xl:basis-9/12 flex flex-col items-center lg:items-start'>
            <div className="flex flex-col gap-1 lg:gap-4 text-center lg:text-start">
              <ShowUp duration={showUpDuration}>
                <h2 className="text-orange-500 font-2xl font-semibold">Información Empresarial</h2>
              </ShowUp>
              <ShowUp duration={showUpDuration}>
                <h3 className="text-black font-normal font-2xl">El León</h3>
              </ShowUp>
            </div>
            <ShowUp duration={showUpDuration}>
              <div className='border-b-2 border-orange-500 w-32 my-2 lg:my-6'></div>
            </ShowUp>
            <div className='py-2'>
              <ShowUp duration={showUpDuration}>
                <p className='text-justify'>
                  El León es una empresa establecida en 1994 con el propósito de brindar soluciones naturales para el dolor muscular. Tenemos nuestra sede en Quilmes, Buenos Aires, y somos distribuidores de nuestros productos en todo el territorio bonaerense.
                </p>
              </ShowUp>
            </div>
          </div>
          <div className='
            lg:basis-5/12 xl:basis-4/12 lg:mx-10 content-center self-center
            transition duration-300 opacity-95 hover:opacity-100
          '>
            <ShowUp duration={showUpDuration} initialX={"100%"} initialY={0}>
              <img
                src={process.env.PUBLIC_URL + '/assets/sulphur-bars.png'}
                alt="Backpain"
                className='md:h-96 lg:h-96 lg:w-full object-fill lg:object-cover rounded-md mb-8 lg:mb-0 shadow-2xl'
                fetchpriority="high"
              />
            </ShowUp>
          </div>
        </div>
        <div className='flex flex-col-reverse lg:flex-row justify-between text-xs lg:my-10 lg:py-4 bg-slate-50'>
          <div className='
            lg:basis-3/12 lg:mx-10 content-center self-center
            transition duration-300 opacity-95 hover:opacity-100
          '>
            <ShowUp duration={showUpDuration} initialX={"-100%"} initialY={0}>
              <img
                src={process.env.PUBLIC_URL + '/assets/backpain.jpg'}
                alt="Bars"
                className='lg:h-96 lg:w-full object-fill lg:object-cover rounded-md shadow-2xl'
                fetchpriority="high"
              />
            </ShowUp>
          </div>
          <div className='px-4 lg:px-20 xl:px-40 py-8 lg:basis-9/12 flex flex-col items-center'>
            <ShowUp duration={showUpDuration}>
              <div className="flex flex-col gap-1 lg:gap-4">
                <h4 className="text-black font-normal font-2xl uppercase">Beneficios de nuestros productos</h4>
              </div>
            </ShowUp>
            <ShowUp duration={showUpDuration}>
              <div className='border-b-2 border-orange-500 w-32 my-2 lg:my-6'></div>
            </ShowUp>
            <div className='py-2 flex flex-col gap-4'>
              <ShowUp duration={showUpDuration}>
                <p className='text-justify'>
                  Las barras están compuestas 100% de Azufre, que es un mineral extraído de zonas volcánicas. El azufre es un antiguo remedio natural que tiene propiedades que ayudan a aliviar los dolores musculares, normalmente provocados por la tensión y el estrés. Las barras de azufre también alivian el dolor causado por la artritis, el reumatismo, los calambres, el dolor en las articulaciones, las bolsas de gases y más.
                </p>
              </ShowUp>
              <ShowUp duration={showUpDuration}>
                <p className='text-justify'>
                  El dolor muscular es generado por iones positivos que ingresan a la célula muscular seguido de un proceso bioquímico que produce dolor. El azufre tiene la propiedad natural de crear equilibrio ya que está cargado de iones negativos y en contacto con la piel atraerá los iones positivos responsables del dolor muscular. Esto provocará el alivio del dolor muscular en cuestión de pocos minutos. Este tratamiento alternativo del dolor está destinado únicamente para uso externo.
                </p>
              </ShowUp>
            </div>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row justify-between text-xs lg:my-10'>
          <div className='px-4 lg:px-20 xl:px-40 py-8 lg:basis-7/12'>
            <div className='py-2 flex flex-col gap-4'>
              <ShowUp duration={showUpDuration}>
                <p className='text-justify'>
                  Tenga siempre a mano Barras de Azufre y disfrute de un estilo de vida libre de dolores musculares.
                </p>
              </ShowUp>
              <ShowUp duration={showUpDuration}>
                <p className='text-justify'>
                  ¿Cómo utilizar la barra de <b>El León</b>? Simplemente pase sobre el área afectada, aplicando una ligera presión. La barra de azufre emitirá crujidos mientras absorbe los iones positivos y los gases atrapados que producen molestias musculares.
                </p>
              </ShowUp>
            </div>
          </div>
          <div className='
            lg:basis-5/12 lg:mx-10 content-center self-center
            transition duration-300 opacity-95 hover:opacity-100
            mb-4 lg:mb-0
          '>
            <ShowUp duration={showUpDuration} initialX={"100%"} initialY={0}>
              <img
                src={process.env.PUBLIC_URL + '/assets/massage.jpg'}
                alt="Masaje utilizando barritas de azufre"
                className='lg:h-96 lg:w-full object-fill lg:object-cover rounded-md shadow-2xl'
                fetchpriority="high"
              />
            </ShowUp>
          </div>
        </div>
      </div>
    </FadeIn>
  )
}

export default About;
