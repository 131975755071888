import { useLocation } from "react-router-dom";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { Link, Outlet } from "react-router-dom";
import { ClockIcon, HomeIcon, PhoneIcon } from '@heroicons/react/24/solid'
import ScrollToTop from 'components/ScrollToTop';

function Root() {
  const { pathname } = useLocation();
  console.log("pathname")
  console.log(pathname)

  const markSelected = (routeName) => {
    return pathname.includes(routeName) ? 'selected' : ''
  }

  return (
    <div>
      <ScrollToTop />
      <FloatingWhatsApp
        avatar={process.env.PUBLIC_URL + '/logo192.png'}
        accountName={"El León"}
        statusMessage={"Responde en menos de 1 hora"}
        chatMessage={"Hola, gracias por contactarte con El León, Fábrica de azufre en barra. ¿Cómo podemos ayudarte?"}
        placeholder={"Escribe un mensaje"}
        phoneNumber={"+5491134274841"}
      />
      <header className="
        h-20 lg:h-28
        fixed w-full top-0 z-50
        backdrop-filter backdrop-blur-md bg-opacity-30
      ">
        <div className="flex w-full justify-between px-4">
          <div className="
            basis-1/5 lg:basis-2/5
            w-20 h-20
            lg:w-96 lg:h-20
            bg-transparent
            p-2
            flex flex-row justify-start items-center gap-x-4
          ">
            <img
              src={process.env.PUBLIC_URL + '/logo512.png'}
              alt="Bars"
              className='object-contain h-full'
              fetchpriority="high"
            />
            <div className='hidden lg:block'>
              <h1 className='font-serif'>El Leon</h1>
            </div>
          </div>
          <div className="basis-4/5 lg:basis-3/5 flex gap-4 items-center justify-end px-2">
            <Link className={`header text-center ${pathname === '/' ? 'selected' : ''}`} to={'/'}>
              Inicio
            </Link>
            <Link className={`header text-center ${markSelected('acerca-nuestro')}`} to={'acerca-nuestro'}>
              Acerca nuestro
            </Link>
            <Link className={`header text-center ${markSelected('contacto')}`} to={'contacto'}>
              Contacto
            </Link>
          </div>
        </div>
      </header>
      <main className=''>
        <Outlet />
      </main>
      <footer className=''>
        <div className="flex gap-4 justify-center py-4">
          <Link className={`header text-center ${pathname === '/' ? 'selected' : ''}`} to={'/'}>
            Inicio
          </Link>
          <Link className={`header text-center ${markSelected('acerca-nuestro')}`} to={'acerca-nuestro'}>
            Acerca nuestro
          </Link>
          <Link className={`header text-center ${markSelected('contacto')}`} to={'contacto'}>
            Contacto
          </Link>
        </div>
        <div className='border-slate-300 border-b-2 mx-6 my-2'></div>
        <div className='p-2'>
          <h2 className='text-2xl text-orange-500 text-center'>El Leon</h2>
        </div>
        <div className='pb-4 px-4'>
          {/* Availability Info */}
          <div className='flex flex-col lg:flex-row gap-6 lg:gap-2 lg:justify-evenly lg:items-start'>
            <div className='flex flex-col'>
              <div className='inline-flex'>
                <div className="self-center">
                  <ClockIcon className="size-6 text-white" />
                </div>
                <h4 className='px-1 text-white'>Horario de atención</h4>
              </div>
              <div className='ml-5 lg:ml-7 flex flex-col'>
                <ul className='list-disc text-white'>
                  <li className=''>
                    <span className='px-1 text-white text-sm'>
                      Lunes a Viernes: 8:00 am. – 6:00 pm.
                    </span>
                  </li>
                  <li>
                    <span className='px-1 text-white text-sm'>
                    Sábados: 8:00 am. – 2:00 pm.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {/* Address Info */}
            <div className='flex flex-col'>
              <div className='inline-flex'>
                <div className="self-center">
                  <HomeIcon className="size-6 text-white" />
                </div>
                <h4 className='px-1 text-white'>Domicilio</h4>
              </div>
              <div className='ml-5 lg:ml-7 flex flex-col'>
                <ul className='list-disc text-white'>
                  <li className=''>
                    <span className='px-1 text-white text-sm'>
                      1 de Mayo 3163, Quilmes Oeste, CP: B1879ADY
                    </span>
                  </li>
                  <li>
                    <span className='px-1 text-white text-sm'>
                      Buenos Aires, Argentina
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {/* Telephone Info */}
            <div className='flex flex-col'>
              <div className='inline-flex'>
                <div className="self-center">
                  <PhoneIcon className="size-6 text-white" />
                </div>
                <h4 className='px-1 text-white'>Teléfono</h4>
              </div>
              <div className='ml-5 lg:ml-7 flex flex-col'>
                <ul className='list-disc text-white'>
                  <li className=''>
                    <span className='px-1 text-white text-sm'>
                      +54 9 11 3427 4841
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className='text-xs italic text-slate-100/75 text-center py-2 border-t-[1px] border-slate-100/25 border-dashed'>Más de 30 años de trayectoria - 2024</p>
        </div>
      </footer>
    </div>
  )
}

export default Root;
