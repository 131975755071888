import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";
import About from "./About";
import Contact from "./Contact";
import Home from './Home';
import Root from './Root'
import settings from 'settings'

function Routes() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Root />}>
        <Route
          path='/'
          element={
            <Home
              fadeInDuration={settings.fadeInDuration}
              showUpDuration={settings.showUpDuration}
            />
          }
        />
        <Route
          path='/acerca-nuestro'
          element={
            <About
              fadeInDuration={settings.fadeInDuration}
              showUpDuration={settings.showUpDuration}
            />
          }
        />
        <Route
          path='/contacto'
          element={
            <Contact
              fadeInDuration={settings.fadeInDuration}
              showUpDuration={settings.showUpDuration}
            />
          }
        />
      </Route>
    )
  );

  return (
    <RouterProvider router={router} />
  )
}

export default Routes
