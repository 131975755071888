import { FadeIn, ShowUp } from "components/Motions";

function App({ fadeInDuration, showUpDuration }) {
  return (
    <FadeIn duration={fadeInDuration} >
      <div className="overflow-x-hidden">
        {/* Banner section */}
        <div className="relative">
          <div className="absolute inset-y-1/2 w-full">
            <ShowUp duration={showUpDuration}>
              <h2 className="text-center text-white font-bold text-xl lg:text-3xl uppercase">
                El elemento esencial para el dolor muscular
              </h2>
            </ShowUp>
          </div>
          <img
            src={process.env.PUBLIC_URL + '/assets/main.jpg'}
            alt=""
            className='w-full object-cover shadow-2xl h-96 lg:h-full'
            fetchpriority="high"
          />
        </div>
        {/* Advertisement */}
        <div className='
          flex flex-col lg:flex-row justify-between
          text-xs
          lg:my-10 lg:mx-10 xl:mx-14 2xl:mx-40
        '>
          <div className='px-4 lg:px-20 xl:px-40 py-8 lg:basis-9/12 flex flex-col items-center lg:items-start'>
            <div className="flex flex-col gap-1 lg:gap-4 text-center lg:text-start">
              <ShowUp duration={showUpDuration}>
                <h2 className="text-orange-500 font-2xl font-semibold">Un remedio natural</h2>
              </ShowUp>
              <ShowUp duration={showUpDuration}>
                <h3 className="text-black font-normal font-2xl">para los dolores y molestias corporales</h3>
              </ShowUp>
            </div>
            <ShowUp duration={showUpDuration}>
              <div className='border-b-2 border-orange-500 w-32 my-2 lg:my-6'></div>
            </ShowUp>
            <div className='py-2'>
              <ShowUp duration={showUpDuration}>
                <p className='text-justify'>
                  El estrés, la tensión y otras condiciones de salud pueden provocar dolores y molestias musculares. Para calmar tus músculos y lograr la relajación, utiliza las barritas de azufre de <b>El León</b>. Proporcionan un alivio natural del dolor y le ayudarán a sentirse mejor en poco tiempo. ¡Consigue el tuyo ahora!
                </p>
              </ShowUp>
            </div>
          </div>
          <div className='lg:basis-3/12 lg:mr-10 transition duration-300 opacity-95 hover:opacity-100 content-center'>
          <ShowUp duration={showUpDuration} initialX={"100%"} initialY={0}>
            <img
              src={process.env.PUBLIC_URL + '/assets/back-massage.jpg'}
              alt="Bars"
              className='lg:h-96 lg:w-full object-fill lg:object-cover lg:rounded-md shadow-2xl'
              fetchpriority="high"
            />
          </ShowUp>
          </div>
        </div>
        {/* Advertisement */}
        <div className='
          text-center bg-slate-50
          px-4 py-8 lg:py-12 lg:px-56 xl:px-60 2xl:px-64
          flex flex-col gap-4 lg:gap-8
        '>
          <ShowUp duration={showUpDuration}>
            <h2 className='text-orange-500'>Nuestro compromiso</h2>
          </ShowUp>
          <ShowUp duration={showUpDuration}>
            <p>
              Estamos aquí para proporcionar un método alternativo para deshacerse del dolor muscular. Creemos que el dolor se puede tratar de forma externa y no invasiva en cualquier momento. Nuestras barras de azufre son 100% naturales, efectivas y convenientes.
            </p>
          </ShowUp>
        </div>
      </div>
    </FadeIn>
  );
}

export default App;
