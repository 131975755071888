import React, { useRef } from 'react';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet'
import { FadeIn, ShowUp } from "components/Motions";

import L from 'leaflet';
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

function Contact({ fadeInDuration, showUpDuration }) {
  const coords = [-34.7434088, -58.2902777]
  const markerRef = useRef(null)
  return (
    <FadeIn duration={fadeInDuration}>
      <div className='flex flex-col overflow-x-hidden'>
        <div className="relative">
          <div className="absolute inset-y-1/2 w-full">
            <ShowUp duration={showUpDuration} initialX={"-100%"} initialY={0}>
              <h2 className="text-center text-white font-bold text-xl lg:text-3xl uppercase">
                Nuestro personal está siempre listo para atenderte
              </h2>
            </ShowUp>
          </div>
          <FadeIn duration={0.5}>
            <img
              src={process.env.PUBLIC_URL + '/assets/contact.jpeg'}
              alt="Contact"
              className='w-full object-cover object-left lg:object-fit h-[30rem] md:h-[40rem] shadow-2xl'
              fetchpriority="high"
            />
          </FadeIn>
        </div>
        <div className='px-4 pt-8 lg:px-56 xl:px-60 2xl:px-64'>
          <div className="flex flex-col gap-1 lg:gap-4 text-center pt-4">
            <ShowUp duration={showUpDuration}>
              <h3 className="text-orange-500 font-2xl font-semibold">Contactate con nosotros</h3>
            </ShowUp>
            <ShowUp duration={showUpDuration}>
              <p className="">
                ¿Tenés alguna pregunta para nosotros? Simplemente comunicate con nuestro equipo a través del botón de whatsapp y nos comunicaremos lo antes posible.
              </p>
            </ShowUp>
          </div>
        </div>
        <div className='w-32 my-2 lg:mt-4 lg:mb-8 self-center mb-4'>
          <ShowUp duration={showUpDuration}>
            <div className='border-b-2 border-orange-500' />
          </ShowUp>
        </div>
        <div>
          <FadeIn duration={fadeInDuration + 3}>
            <MapContainer center={coords} zoom={12} style={{ height: '400px', width: '100%' }} id="map" scrollWheelZoom={false}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={coords} ref={markerRef}>
                <Tooltip direction="top" permanent offset={[-14, -14]}>
                  El León, Fabrica de azufre en barra
                </Tooltip>
              </Marker>
            </MapContainer>
          </FadeIn>
        </div>
      </div>
    </FadeIn>
  );
}

export default Contact;
