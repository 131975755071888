import { motion } from "framer-motion";

function FadeIn({ children, duration }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: duration }}
    >
      { children }
    </motion.div>
  )
}

function ShowUp({ children, duration, initialY = "100%", endY = 0, initialX = 0, endX = 0 }) {
  return (
    <motion.div
      initial={{ x: initialX, y: initialY }}
      transition={{ duration: duration + 0.5 }}
      whileInView={{x: endX, y: endY}}
      viewport={{ once: true }}
    >
      { children }
    </motion.div>
  )
}

export {
  FadeIn,
  ShowUp
}
